.catDesc p {
	margin: 10px;
	text-align: start !important;
}

.catDesc p:last-child br {
	display: none;
}

.catDesc > p > img {
	width: 900px !important;
	height: auto !important ;
}

.catDesc p strong {
	font-weight: 900;
	font-size: 18px;
}

.catDesc ul {
	/* margin: 15px; */
	padding: 0px 0px 15px 25px;
	list-style-type: disc;
}

.catDesc > h2 > span > strong {
	font-weight: 900;
	font-size: 18px;
}

.catDesc > ul > strong {
	font-weight: bold;
}

/* for left and right col */
.wordWrapFamily {
	word-wrap: break-word;
	max-width: 40ch;
}
.categoryText h2 .buttonBck button {
	display: none;
}
@media only screen and (min-width: 649px) and (max-width: 1024px) {
	.familyCategory {
		position: absolute;
		right: 2%;
		top: 15px;
		max-width: 238px;
	}
}

@media only screen and (min-width: 280px) and (max-width: 767px) {
	.familyCategory {
		margin-top: 10px;
	}
}

@media only screen and (min-width: 280px) and (max-width: 1024px) {
	.detailImg {
		display: none;
	}
	.headingName {
		margin-top: 1.5rem;
	}
	.categoryDetailPage {
		display: flex;
		flex-direction: column-reverse;
	}
}

@media all and (max-width: 576px) {
	.categoryProductDetailWrapper {
		max-width: 250px !important;
	}
}
@media all and (max-width: 375px) {
	.familyCategory {
		width: 100%;
	}
}
@media all and (min-width: 1441px) {
	.categoryProductsListContainer {
		grid-template-columns: repeat(4, 1fr) !important;
	}
	.featuredProductsImgsContainer {
		grid-template-columns: repeat(4, 1fr) !important;
	}
}
