.popUpImg,
.enlargedImg {
	max-width: 1000px;
	max-height: 1000px;
}

.popUpImg img {
	width: 100% !important;
}

.product {
	width: 100%;
	padding-bottom: 2rem !important;
}

.faqAnswer {
	border-bottom-left-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;
}

.img_container {
	width: 100%;
	max-width: 500px;
}

.img_container img,
.img_container span {
	width: 100% !important;
	height: 100% !important;
}

.product_detail {
	margin-left: 1rem;
	margin-right: 1rem;
}

.proActiveImg {
	border: 2px solid #52a0f2 !important;
}
/* .activeHover:hover {
	border: 1px solid #52a0f2 !important;
} */
@media screen and (max-width: 1024px) {
	.enlargedImg {
		max-height: 80vh;
	}
}
