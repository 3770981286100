.detail p {
	font-size: 15px;
	line-height: 1.5rem;
	margin-bottom: 16px;
	text-align: start;
	cursor: initial;
	margin-top: 2px;
	font-family: 'Manrope', sans-serif !important;
}

.detail > p > span {
	font-size: 15px;
	line-height: 1.5rem;
	margin-top: 1rem;
	text-align: start;
	cursor: initial;
	font-family: 'Manrope', sans-serif !important;
}
.detail > h2 > span > span,
.detail > ul > li > span > span,
.detail > p > span > span,
.detail > h3 > span > span,
.detail > div > table p span {
	font-family: 'Manrope', sans-serif !important;
}
.detail > ul > li > strong {
	font-weight: bold !important;
	font-size: 18px !important;
}
.detail > p > a {
	font-weight: 800;
	text-decoration: underline;
}
.detail > h3 > u > strong,
.detail > h3 > strong > u {
	font-size: 22px;
	font-weight: 900;
	line-height: 4rem;
}

.detail p strong {
	font-weight: 900;
	font-size: 18px;
}
.detail > p > em > strong {
	font-weight: 900;
	font-style: italic;
	font-size: 17px;
}
.detail > ul {
	margin: 15px;
	padding: 15px 0px 15px 25px;
	list-style-type: disc;
}
.detail ul li {
	font-size: 15px;
}
.detail ol {
	margin: 15px;
	padding: 15px 0px 15px 25px;
	list-style-type: decimal;
}
.detail > h1 {
	font-size: 48px !important;
	line-height: 1 !important;
	font-weight: 700 !important;
}
.detail h2 {
	font-size: 27px !important;
	line-height: 36px !important;
	font-weight: 700;
}

.detail h3 {
	font-size: 24px !important;
	line-height: 30px !important;
	font-weight: 900;
}
.detail > table {
	border-collapse: collapse;
	width: 80%;
	margin: auto;
}
.detail > table td {
	height: 18px;
	text-align: center;
	border: 1px solid black;
	padding: 5px;
	vertical-align: middle;
	font-size: 15px;
}
.detail div table {
	border-collapse: collapse !important;
	width: 960px !important;
	margin-bottom: 25px;
	font-size: 15px;
}
.detail div table td {
	text-align: center;
	border: 1px solid black;
	padding: 5px;
	vertical-align: middle;
	font-size: 15px;
}
.detail div table td p,
.detail div table td strong {
	margin: 10px 0px;
	font-size: 15px;
	/* font-weight: bold; */
	text-align: center;
}
.detail div {
	width: 100%;
	overflow: auto;
}

.detail .return-form-check-on-next {
	display: none !important;
}
@media all and (max-width: 350px) {
	.gRecaptchaa {
		transform: scale(0.85);
		transform-origin: 0 0;
	}
}
