/* .productDetail p strong {
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 600;
	color: black;
} */

.productDetail p {
	--tw-text-opacity: 1;
	color: rgba(75, 85, 99, var(--tw-text-opacity));
	font-size: 0.875rem;
	line-height: 1.25rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.productDetail h3,
.productDetail span {
	border: none;
	outline: none;
}

.productDetail img {
	width: 550px !important;
	height: auto !important ;
	margin-bottom: 20px;
	margin: auto;
}

.productDetail > ul {
	list-style-type: disc;
	margin: 20px;
	line-height: 25px;
}

.productDetail > h2 > span {
	font-size: 1.125rem;
	/* line-height: 1.75rem; */
	font-weight: 600;
	color: black;
	margin-top: 10px !important;
}

.productDetail iframe {
	width: 100%;
	height: 270px;
	max-width: 500px;
	margin: auto;
}

.productDetail > p > strong > u {
	font-size: 1.125rem;
	/* line-height: 1.75rem; */
	font-weight: 600;
	color: black;
	margin-top: 10px !important;
}

.productDetail > h2 > strong {
	font-size: 1.125rem;
	/* line-height: 1.75rem; */
	font-weight: 600;
	color: black;
	margin-top: 10px !important;
}

.productDetail > p > strong {
	font-size: 1.125rem;
	/* line-height: 1.75rem; */
	font-weight: 600;
	color: black;
	margin-top: 10px !important;
}

@media (max-width: 760px) {
	.productDetail iframe {
		height: 150px;
	}
}
